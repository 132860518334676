import React from "react"

const SocialLinks = ({ data }) => {
  return (
    <>
      {data ? (
        <ul className="footer-social">
          {data.map((menuItem) => {
            return (
              <li key={menuItem?.icon?.id}>
                <a href={menuItem?.url} target="_blank">
                  <img
                    src={menuItem?.icon?.sourceUrl}
                    alt={menuItem?.icon?.altText}
                  />
                </a>
              </li>
            )
          })}
        </ul>
      ) : null}
    </>
  )
}

export default SocialLinks
