import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

// const recaptchaRef = React.createRef();

class NewsletterForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            message: '',
            isVerified: false
        }
    }

    handleChange(ev) {
        this.setState({
            email: ev.target.value
        })
    }

    componentDidMount() {
        // if (recaptchaRef) {
        //     recaptchaRef.current.execute();
        // }
    }

    handleSubmit(ev) {
        ev.preventDefault();
        if (this.state.email === '') {
            return false;
        }
        // if (this.state.isVerified === true) {
            const xhr = new XMLHttpRequest()
            const form = ev.target
            xhr.open(form.method, form.action)
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                const { status, message } = JSON.parse(xhr.response)
                if (xhr.status === 200) {
                    form.reset();
                    this.setState({ message: 'Thank you for subscribing', status: status });
                } else {
                    this.setState({ message: message, status: status });
                }
            }
            xhr.send(JSON.stringify({ email: this.state.email }))
        // } else {
        //     this.setState({ message: 'You are not human.'})
        // }
    }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
            console.log("verified", this.state.isVerified);
        }
    }

    render() {
        return (
            <form method="post" action={'https://' + process.env.WP_BASE_URL + '/wp-json/newsletter/v1/signup'} onSubmit={(e) => this.handleSubmit(e)}>
                <div className="form-wrapper">
                    <input type="email" name="email" placeholder="Email" aria-label="Email" onChange={(e) => this.handleChange(e)} />
                    <button type="submit" className="button">
                        Sign Up
                    </button>
                </div>
                {/* <ReCAPTCHA
                    sitekey="6LcQ0f8ZAAAAAGoXvEEUH-1IjT3yAWfb0PiaSWOd"
                    onChange={this.handleVerify}
                    ref={recaptchaRef}
                    theme="dark"
                    size="invisible"
                /> */}
                <div className="response">
                    {this.state.message}
                </div>
            </form>
        )
    }
}

const NewsletterSignUp = ({ color }) => {
    return (
        <div className={`newsletter ${color}`}>
            <NewsletterForm />
        </div>
    )
}

export default NewsletterSignUp
