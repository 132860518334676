import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import NewsletterSignUp from "./NewsletterSignUp"
import SocialLinks from "./SocialLinks"
import CookieConsent from "react-cookie-consent"

// showOnPages
const Footer = ({ pageSlug }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsOptions {
          acfOptions {
            copy
            top {
              button
              buttonUrl
              content
              content2
              form
              title
              title2
            }
            bottom {
              button
              buttonUrl
              content
              content2
              content3
              title
              title2
              title3
              logo {
                sourceUrl
                altText
              }
              logo2 {
                sourceUrl
                altText
              }
            }
            socialTitle
            social {
              icon {
                id
                sourceUrl
                altText
              }
              url
            }
            showPopup
            video
            content
          }
        }
      }
    }
  `)

  const { ...options } = wp.acfOptionsOptions.acfOptions
  let slug = pageSlug === "home" ? "/" : pageSlug
  // if (typeof window !== 'undefined' && window.location) {
  //   const paths = window.location.pathname.split('/')
  //   slug = (paths[1]) ? paths[1] : '/';
  // }
  const showOnPages = ["/", "stand-designer"]
  const [gpdr, setGpdr] = useState(true)
  const isHome = slug === "home" || slug === "/" ? true : false
  const [imageCompare, setImageCompare] = useState(false)

  useEffect(() => {
    const canUseDOM = !!(
      (typeof window !== 'undefined' && window.document && window.document.createElement)
    )

    const isImageCompare = (canUseDOM) ? window.location.pathname.split('/')[1] === "image-compare" : false;

    setImageCompare(isImageCompare)
  }, [])

  return (
    <footer id="site-footer" role="contentinfo" className="footer-group">
      {options.top && !imageCompare ? (
        <div
          className={`footer-top ${showOnPages.includes(slug) ? "show" : ""}`}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <h3
                  dangerouslySetInnerHTML={{ __html: options.top.title }}
                ></h3>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: options.top.content }}
                ></div>
                <div className="button-wrap">
                  <a href={options.top.buttonUrl} className="button">
                    {options.top.button}
                  </a>
                </div>
              </div>
              <div className="col">
                <h3
                  dangerouslySetInnerHTML={{ __html: options.top.title2 }}
                ></h3>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: options.top.content2 }}
                ></div>
                <NewsletterSignUp color="dark" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {options.bottom && !imageCompare ? (
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              {options.bottom.logo ? (
                <div className="col">
                  <div className="logo-wrap">
                    <img
                      src={options.bottom.logo.sourceUrl}
                      alt={options.bottom.logo.altText}
                    />
                  </div>
				  
                </div>
              ) : (
                ""
              )}

              <div className="col">
			  
                <div className="button-wrap">
				
                  <a
                    href={options.bottom.buttonUrl}
                    className="button button-round"
                  >
                    {options.bottom.button}
                  </a>
				 
                </div>
              </div>

              <div className="col">
                <h4
                  dangerouslySetInnerHTML={{ __html: options.bottom.title2 }}
                ></h4>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: options.bottom.content2 }}
                ></div>
              </div>

              <div className="col">
                <h4
                  dangerouslySetInnerHTML={{ __html: options.bottom.title3 }}
                ></h4>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: options.bottom.content3 }}
                ></div>
              </div>

              {options.bottom.logo2 ? (
                <div className="col">
                  <div className="logo-wrap">
                    <a href="https://www.alpinelis.com/" target="_blank">
                      <img
                        src={options.bottom.logo2.sourceUrl}
                        alt={options.bottom.logo2.altText}
                      />
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="footer-credits">
        <div className="container">
          {options.copy ? (
            <p
              className="footer-copyright"
              dangerouslySetInnerHTML={{ __html: options.copy }}
            ></p>
          ) : (
            ""
          )}

          <SocialLinks data={options.social} />
        </div>
      </div>

      {gpdr && isHome ? (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
        >
          {" "}
          This website uses cookies to improve your experience.{" "}
          <button className="close" onClick={() => setGpdr(false)}>
            x
          </button>{" "}
        </CookieConsent>
      ) : (
        ""
      )}
    </footer>
  )
}

export default Footer
