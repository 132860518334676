import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Menu from "./Menu"
import ToggleIcon from "../assets/svg/toggle.svg"
import Logo from "../assets/svg/logo.svg"


const Header = ({ pageContext, toggleBackdrop, user, ...props }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  var canUseDOM = !!(
    (typeof window !== 'undefined' && window.document && window.document.createElement)
  );

  var pathArray = (canUseDOM) ? window.location.pathname.split('/') : '/';

  if ( pathArray[1] !== 'image-compare' )
  return (
    <header id="site-header" role="banner">
      <div className="header-top">
        <h1 className="site-logo">
          <Link
            to="/"
          >
            <img src={ Logo } alt={ wp.generalSettings.title } />
          </Link>
        </h1>

        <button
          className="toggle nav-toggle mobile-nav-toggle"
          onClick={(e) => toggleBackdrop(e)}
        >
          <span className="bar bar-top"></span>
          <span className="bar bar-middle"></span>
          <span className="bar bar-bottom"></span>
        </button>
      </div>

      <div className="header-navigation-wrapper">
        <Menu />
      </div>
      <div className="auth">
        {user ? (
          <Link to='/my-account/'>
            My Account
          </Link>
        ) : (
          <Link to='/login'>
            Sign in
          </Link>
        )}
      </div>
    </header>
  )

  return (
      <div></div>
  )
}

export default Header
