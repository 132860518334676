import {useEffect, useReducer, useState} from "react";

export default function useAuthState(firebase) {

	const [auth, setAuth] = useState(undefined);
	const [userData, setUserData] = useState(null)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [subscriptions, setSubscriptions] = useState(null)
	const [seats, setSeats] = useState(null)
	const [seatDocs, setSeatsDocs] = useState(null)

	const [state, dispatch] = useReducer(
		(state, action) => {
			switch (action.type) {
				case "auth_state_changed":
					return {
						...state,
						user: action.user,
						loading: false,
					};
				case "error":
					return {
						...state,
						error: action.error,
						loading: false,
					};
			}
		},
		{
			user: undefined,
			loading: true,
			error: undefined,
		}
	);

	useEffect(() => {
		setAuth(firebase.auth());
	}, [firebase]);

	useEffect(() => {
		if (auth === undefined) return;

		const unsubscribe = auth.onAuthStateChanged(
			user => {
				dispatch({type: "auth_state_changed", user});
			},
			error => {
				dispatch({type: "error", error});
			}
		);

		return () => {
			unsubscribe();
		};
	}, [auth]);

	useEffect(() => {
        if (state.user === undefined || state.user === null || typeof state.user === undefined || !state.user) return
		const unsubscribe = firebase.firestore()
			.collection('Users')
			.doc(state.user.uid)
			.onSnapshot(doc => {
				if (doc) {
					if (doc.exists) {
						setUserData(doc.data())
					}
				}
			}, err => {
				console.error(err)
			})
        return () => unsubscribe()
    }, [state.user])

	useEffect(() => {
        if (state.user === undefined || state.user === null || typeof state.user === undefined || !state.user) return
		const unsubscribe = firebase.firestore()
			.collection('Users')
			.doc(state.user.uid)
			.collection('payment_methods')
			.onSnapshot(querySnapshot => {
				if (querySnapshot) {
					let methods = [];
					querySnapshot.forEach(function(doc) {
						const { card } = doc.data();
						if (card) {
							methods.push(doc.data());
						}
					});
					setPaymentMethods(methods)
				}
			}, err => {
				console.error(err)
			})
        return () => unsubscribe()
    }, [state.user])

	useEffect(() => {
        if (state.user === undefined || state.user === null || typeof state.user === undefined || !state.user) return
		const unsubscribe = firebase.firestore()
			.collection('Users')
			.doc(state.user.uid)
			.collection('payments')
			.onSnapshot(querySnapshot => {
				if (querySnapshot) {
					let subscriptionsArray = [];
					querySnapshot.forEach(function(doc) {
						subscriptionsArray.push({docId: doc.id, ...doc.data()});
					});
					setSubscriptions(subscriptionsArray)
				}
			}, err => {
				console.error(err)
			})
        return () => unsubscribe()
    }, [state.user])

	useEffect(() => {
		if (state.user === undefined || state.user === null || typeof state.user === undefined || !state.user) return
		const fetchData = async () => {
			try {
				let seats = [];
				let seatDocs = [];
				const seatsRef = firebase.firestore().collection('Users').doc(state.user.uid).collection('seats')
				const userSeats = await seatsRef.get()
				for(const doc of userSeats.docs) {
					const { seatRef } = doc.data()
					const seatData = await seatRef.get()
					if (seatData.data()) {
						seatDocs.push({id: seatData.id, ...seatData.data()})
						for (const s of seatData.data().seats) {
							seats.push(s)
						}
					}
				}
				setSeatsDocs(seatDocs)
				setSeats(seats)
			} catch (e) {
				console.error(e)
			}
		}
		fetchData()

	}, [state.user])
	
	// useEffect(() => {
    //     if (state.user === undefined || state.user === null || typeof state.user === undefined || !state.user) return
	// 	const unsubscribe = firebase.firestore()
	// 		.collection('Users')
	// 		.doc(state.user.uid)
	// 		.collection('seats')
	// 		.onSnapshot(querySnapshot => {
	// 			if (querySnapshot) {
	// 				let subscriptions = [];
	// 				let totalSeats = [];
	// 				querySnapshot.forEach((doc) => {
	// 					const { seatRef } = doc.data();
	// 					seatRef.onSnapshot(snap => {
	// 						const { seats } = snap.data();
	// 						seats.forEach(seat => {
	// 							console.log(seat)
	// 							totalSeats.push(seat);
	// 						})
	// 						subscriptions.push({docId: snap.id, seats: seats});
	// 					})
	// 				});
	// 				setSeats(totalSeats)
	// 				setSeatsDocs(subscriptions)
	// 			}
	// 		}, err => {
	// 			console.error(err)
	// 		})
    //     return () => unsubscribe()
	// }, [state.user])


	return [state.user, state.loading, state.error, userData, paymentMethods, subscriptions, seats, seatDocs];
}